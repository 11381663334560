import request from '../request'
import axios from 'axios'
import qs from 'qs';
const token = localStorage.getItem('Blade-Auth-Token');
// export const login = (params) => {
//     return axios.post(`/api/blade-party-admin/dict-biz/dictionary${params.param}`, params)
//         .then(res => res)
//         .catch(err => { console.log(err) });
// };
//请求列表党群和书记心里话
export const getLetterList = (params) => {
    return request({
        url: `/blade-party-admin/min/innermost/list`,
        methods: 'post',
        data: params,
        method: 'POST',
        headers: {
            'Blade-Auth': token,
            'User-Type': 'DINGTALK'
        }
    })
};

//请求列表我的心里话
export const getMyWord = (params) => {
    return request({
        url: `/blade-party-admin/min/innermost/my/list`,
        data: params,
        method: 'POST',
        headers: {
            'Blade-Auth': token,
            'User-Type': 'DINGTALK'
        }
    })
};

//写信
export const writeLetter = (params) => {
    return request({
        url: `/blade-party-admin/min/innermost/write`,
        data: params,
        method: 'POST',
        headers: {
            'Blade-Auth': token,
            'User-Type': 'DINGTALK'
        }
    })
};
//我要写信中请求书记列表
export const requestSecre = (params) => {
    return request({
        url: `/blade-party-admin/min/innermost/secretary/list`,
        data: params,
        method: 'POST',
        headers: {
            'Blade-Auth': token,
            'User-Type': 'DINGTALK'
        }
    })
};
//进入书记和党群详情
export const toDetail = (params) => {
    return request({
        url: `/blade-party-admin/min/innermost/detail`,
        data: params,
        method: 'POST',
        headers: {
            'Blade-Auth': token,
            'User-Type': 'DINGTALK'
        }
    })
};

//详情的评论列表
export const requestCommentList = (params) => {
    return request({
        url: `/blade-party-admin/min/innermost/comment/list`,
        data: params,
        method: 'POST',
        headers: {
            'Blade-Auth': token,
            'User-Type': 'DINGTALK'
        }
    })
};

//发送评论
export const sendComment = (params) => {
    return request({
        url: `/blade-party-admin/min/innermost/comment`,
        data: params,
        method: 'POST',
        headers: {
            'Blade-Auth': token,
            'User-Type': 'DINGTALK'
        }
    })
};

// 文件上传
export const uploadFile = (params) => {
    return axios.post(`/blade-party-admin/file/put-file`, params, { headers: { 'User-Type': 'DINGTALK', 'Blade-Auth': token, 'Content-Type': 'multipart/form-data' } });
};
