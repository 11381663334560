<template>
  <div class="heart-word">
    <!-- <van-nav-bar title="心里话" left-arrow @click-left="onClickLeft" /> -->
    <ul class="tab-title">
      <li v-for="(item,index) in tabList" :key="index">
        <span
          @click="changeList(item.type)"
          :class="[activeType==item.type ? 'clickStyle' : '']"
        >{{item.title}}</span>
      </li>
    </ul>
    <div class="heart-word-body">
      <ul class="heart-word-list">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            :error.sync="error"
            error-text="请求失败，点击重新加载"
            @load="onLoad"
            :offset="70"
          >
            <li
              v-for="(item,index) in letterList"
              :key="index"
              @click="goDetail(item.id,item.myFlag)"
            >
              <div class="header">
                <div class="img">
                  <img :src="item.avatar" alt />
                </div>
                <div class="user-info">
                  <h3>
                    {{item.realName}}
                    <span v-if="activeType==3">{{item.myFlag==1? '写给我的':'我发布的'}}</span>
                    <span
                      v-if="item.myFlag==1 && activeType==3"
                      class="read"
                      :class="[item.isRead==0 ? 'notRead' : '']"
                    >{{item.isRead? '已读':'未读'}}</span>
                  </h3>
                  <p>{{item.deptName}}</p>
                </div>
              </div>
              <div class="content">
                <p>{{item.sendMessage}}</p>
                <div class="img-list" v-if="item.picture">
                  <div v-for="(itm,ind) in item.picture.split(',')" :key="ind">
                    <img :src="itm" alt />
                  </div>
                </div>
              </div>
              <div class="like" v-show="activeType!=2">
                <div class="see">
                  <van-icon name="eye-o" class="icon-yanjing" />
                  <span class="see-num">{{item.browseNum}}</span>
                </div>
                <p>{{item.createTime}}</p>
              </div>
            </li>
          </van-list>
        </van-pull-refresh>
      </ul>
    </div>
    <div class="write-letter" @click="writeLetterFn">
      <img src="./../../assets/img/writeLetter.png" alt />
    </div>
  </div>
</template>
<script>
import { getLetterList, getMyWord } from '@/api/heartWord';
export default {
  data() {
    return {
      active: 0,
      activeType: 1,
      currentPage: 1,
      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      tabList: [
        {
          id: 1,
          type: 1,
          title: '书记公开信'
        },
        {
          id: 2,
          type: 2,
          title: '党群公开信'
        },
        {
          id: 3,
          type: 3,
          title: '我的心里话'
        }
      ],
      letterList: [],
      getRecordStatus: false // 查询的delay
    };
  },
  methods: {
    onClickLeft() {
      this.$router.push({ name: 'mainPage' });
    },
    writeLetterFn() {
      this.$router.push({ name: 'writeLetter' });
    },
    //请求党群和书记
    requestList(type) {
      this.getRecordStatus = true;
      getLetterList({
        type,
        current: this.currentPage,
        size: 8
      })
        .then(res => {
          // this.letterList = res.data.records;
          if (res.success) {
            this.currentPage++;
            //加载完成
            if (res.data.records.length === 0) {
              this.finished = true;
              return false;
            }
            this.loading = false;
            this.error = false;
            let arr = [...this.letterList, ...res.data.records];
            this.letterList = arr;
          } else {
            this.error = true;
          }
          this.getRecordStatus = false;
        })
        .catch(err => {
          this.error = true;
          this.getRecordStatus = false;
        });
    },
    //请求第一页数据
    requestFirstList(type) {
      this.getRecordStatus = true;
      getLetterList({
        type,
        current: 1,
        size: 8
      })
        .then(res => {
          if (res.success) {
            this.letterList = res.data.records;
            if (res.data.records.length !== 4) {
              this.finished = true;
            }
            this.loading = false;
            this.error = false;
            this.refreshing = false;
          } else {
            this.error = true;
          }
          this.getRecordStatus = false;
        })
        .catch(err => {
          this.error = true;
          this.loading = false;
          this.getRecordStatus = false;
        });
    },
    //请求我的第一页数据
    requestMyFirst() {
      getMyWord({
        current: 1,
        size: 8
      })
        .then(res => {
          if (res.success) {
            this.letterList = res.data.records;
            if (res.data.records.length !== 4) {
              this.finished = true;
            }
            this.loading = false;
            this.error = false;
            this.refreshing = false;
          } else {
            this.error = true;
          }
        })
        .catch(err => {
          this.error = true;
          this.loading = false;
        });
    },
    //请求我的心里话
    requestMy() {
      getMyWord({
        current: this.currentPage,
        size: 8
      })
        .then(res => {
          // this.letterList = res.data.records;
          if (res.success) {
            this.currentPage++;
            //加载完成
            if (res.data.records.length === 0) {
              this.finished = true;
              return false;
            }
            this.loading = false;
            this.error = false;
            let arr = [...this.letterList, ...res.data.records];
            this.letterList = arr;
          } else {
            this.error = true;
          }
        })
        .catch(err => {
          this.error = true;
        });
    },
    //点击tab时切换
    changeList(type) {
      this.activeType = type;
      this.letterList = [];
      this.currentPage = 1;
      this.finished = false;
      if (type === 3) {
        this.requestMyFirst();
      } else {
        this.requestFirstList(type);
      }
      this.currentPage = 2;
    },
    goDetail(id, myFlag) {
      // if (this.activeType == 3 && myFlag == 1) {
      //   this.$router.push({ name: 'toMeDetail', params: { id: id } });
      //   //存储type
      //   return false;
      // }
      this.$router.push({ name: 'letterDetail', params: { id: id } });
    },
    //加载更多数据
    onLoad() {
      setTimeout(() => {
        if (this.refreshing) {
          if (this.activeType === 3) {
            this.requestMyFirst();
          } else {
            this.requestFirstList(this.activeType);
          }
          this.currentPage = 2;
        } else {
          if (this.activeType === 3) {
            this.requestMy(this.currentPage);
          } else {
            this.requestList(this.activeType);
          }
        }

        this.loading = false;
      }, 500);
    },
    //刷新数据
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.letterList = [];
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    }
  },
  created() {
    //请求第一页数据
    // this.requestFirstList(1);
  },
  // 如果从首页返回就退出
  beforeRouteEnter(to, from, next) {
    let selectRow = 1;
    if (from.name === 'letterDetail') {
      selectRow = Number(localStorage.getItem('letterListSelectRow'));
    }
    next(vm => {
      //因为当钩子执行前，组件实例还没被创建
      // vm 就是当前组件的实例相当于上面的 this，所以在 next 方法里你就可以把 vm 当 this 来用了。

      vm.activeType = selectRow; //当前组件的实例
      // vm.onLoad();
    });
  },
  beforeRouteLeave(to, from, next) {
    // 导航离开该组件的对应路由时调用
    // 可以访问组件实例 `this`
    const selectRow = this.activeType;
    localStorage.setItem('letterListSelectRow', selectRow);
    next();
  }
};
</script>
<style scoped lang="scss">
// 修改vant样式：>>>针对css, /deep/针对scss和less
$px: 1rem/75;
.heart-word {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  .van-nav-bar {
    height: 88 * $px;
    /deep/ .van-icon {
      color: #222;
    }
  }
  .tab-title {
    height: 88 * $px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    li {
      font-size: 28 * $px;
      height: 100%;
      line-height: 88 * $px;
      span {
        display: inline-block;
        height: 100%;
        box-sizing: border-box;
        &.clickStyle {
          border-bottom: 2 * $px solid #ff192f;
          color: #ff192f;
        }
      }
    }
  }
  .heart-word-body {
    flex: 1;
    overflow: scroll;
    background: #f3f3f3;
    .heart-word-list {
      padding: 0 20 * $px;
      li {
        margin-top: 20 * $px;
        padding: 20 * $px;
        font-size: 28 * $px;
        color: #555;
        width: 710 * $px;
        background: rgba(255, 255, 255, 1);
        border-radius: 8 * $px;
        box-sizing: border-box;
        .header {
          display: flex;
          border-bottom: 2 * $px solid #f3f3f3;
          padding-bottom: 18 * $px;
          //   line-height: 118 * $px;
          .img {
            width: 80 * $px;
            height: 80 * $px;
            background: url('./../../assets/img/avatar.png') 100% 100%;
            background-size: cover;
            border-radius: 50%;
            margin-right: 18 * $px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
          .user-info {
            h3 {
              position: relative;
              font-size: 32 * $px;
              color: #222;
              margin-bottom: 6 * $px;
              span {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 102 * $px;
                height: 36 * $px;
                line-height: 36 * $px;
                border: 1.2 * $px solid rgba(85, 85, 85, 1);
                border-radius: 18 * $px;
                font-size: 20 * $px;
                color: #555;
                &.read {
                  width: 72 * $px;
                  position: absolute;
                  right: 0;
                  top: 5 * $px;
                }
                &.notRead {
                  border: 2 * $px solid rgba(255, 25, 47, 1);
                  color: rgba(255, 25, 47, 1);
                  background: #fff;
                }
              }
            }
            p {
              font-size: 24 * $px;
              color: #999;
              width: 550 * $px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
        .content {
          p {
            margin: 18 * $px 0;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
          .img-list {
            display: flex;
            justify-content: flex-start;
            & > div {
              background: url('./../../assets/img/list-compre.png') center;
              width: 200 * $px;
              height: 200 * $px;
              &:nth-of-type(2) {
                margin: 0 27 * $px;
              }
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        .like {
          display: flex;
          justify-content: space-between;
          margin-top: 18 * $px;
          font-size: 22 * $px;
          .see {
            color: #555;
            .icon-yanjing {
              font-size: 32 * $px;
              margin-right: 5 * $px;
              vertical-align: bottom;
            }
            .see-num {
              font-size: 24 * $px;
            }
          }
          p {
            color: #999;
          }
        }
      }
    }
  }
  .write-letter {
    position: fixed;
    width: 143 * $px;
    height: 143 * $px;
    right: 28 * $px;
    bottom: 28 * $px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>